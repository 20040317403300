import type { ComponentPropsWithoutRef, FunctionComponent, ReactElement } from 'react'
import React, { cloneElement, isValidElement, useRef } from 'react'
import { StandardLink, Typography } from '@which/seatbelt'

import classnames from 'classnames'

import type { Link } from '../../../../generated/frontend'
import { ShowMore } from '../../../../shared'
import type { ShowMoreContentRenderer } from '../../../../shared/components/ShowMore/ShowMore'
import { useMatchMedia } from '../../../../shared/hooks/useMatchMedia'
import styles from './MostReadLinks.module.scss'

export const MostReadLinks: FunctionComponent<Props> = ({ title, links }) => {
  const ref = useRef<HTMLElement>(null)
  const tabletOrAbove = useMatchMedia('(min-width: 768px)')

  if (!links || links.length === 0) {
    return null
  }

  const mappedListItems: ReactElement[] = links.map((link, index) => (
    <MostReadListItem
      index={index}
      key={`${link.href}-${index}`}
      id={`homepage-most-read-link-${index}`}
      data-which-id="Homepage Link"
      data-section={title}
      data-card-name={link.text}
      data-index={index + 1}
      {...link}
    />
  ))

  // Takes 6th link out of tab order on mobile and applies ID for focusing
  // Extra links added tablet and above via CSS rather than useMatchMedia to avoid CLS
  const showMoreContentRenderer: ShowMoreContentRenderer = (linkElements) => {
    if (!Array.isArray(linkElements)) {
      return linkElements
    }

    const isExpanded = linkElements.length > 6

    if (isExpanded) {
      return <ol className={classnames(styles.list, styles.listExpanded)}>{linkElements}</ol>
    }

    // @TODO: Might want to find a better way to avoid casting linkElements[5] below
    return (
      <ol className={styles.list}>
        {linkElements.slice(0, 5)}
        {isValidElement(linkElements[5] as React.ReactNodeArray) && !tabletOrAbove
          ? cloneElement(linkElements[5], {
              isDecorative: true,
            })
          : linkElements[5]}
        {mappedListItems.slice(6).map((element) =>
          cloneElement(element, {
            className: styles.mobileHiddenLink,
          })
        )}
      </ol>
    )
  }

  // Focus 6th link when show more button is clicked
  const focusCallback = () => {
    // TODO CTX-1472: change to using a ref when ref forwarding is added to Link components
    ref.current?.querySelector<HTMLElement>('#homepage-most-read-link-5')?.focus()
  }

  const headingId = 'homepage-most-read-links-heading'

  const moreButtonProps = {
    'data-which-id': 'Homepage Link',
    'data-section': title,
    'data-card-name': 'Show more',
    className: styles.moreButton,
  }

  return (
    <section aria-labelledby={headingId} ref={ref}>
      <Typography id={headingId} tag="h2" textStyle="title-600" className={styles.header}>
        {title}
      </Typography>
      <ShowMore
        contentRenderer={showMoreContentRenderer}
        initialCount={6}
        callback={focusCallback}
        moreButtonProps={moreButtonProps}
        className={styles.showMoreWrapper}
      >
        {mappedListItems}
      </ShowMore>
    </section>
  )
}

///////// IMPLEMENTATION /////////

const MostReadListItem: FunctionComponent<MostReadListItemProps> = ({
  index,
  href,
  text,
  isDecorative = false,
  className,
  ...rest
}) => (
  <li
    className={classnames(styles.listItem, className)}
    {...(isDecorative && { 'aria-hidden': true })}
  >
    <Typography textStyle="body-intro" tag="span" className={styles.number}>{`${
      index + 1
    }.`}</Typography>
    <StandardLink
      className={styles.link}
      href={href}
      {...(isDecorative && {
        tabIndex: -1,
        className: classnames(styles.link, styles.decorativeLink),
      })}
      {...rest}
    >
      {text}
    </StandardLink>
  </li>
)

type MostReadListItemProps = {
  index: number
  isDecorative?: boolean
  className?: string
} & Link &
  ComponentPropsWithoutRef<'li'>

type Props = {
  title: string
  links: Link[]
}
