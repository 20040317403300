import type { FunctionComponent } from 'react'
import React from 'react'
import { StandardLink, Typography } from '@which/seatbelt'

import { section, seoLinkList } from '../../constants/seoLinks'
import styles from './SeoLinks.module.scss'

export const SeoLinks: FunctionComponent = () => (
  <section aria-labelledby="seolinks-header">
    <div className={styles.headerWrapper}>
      <Typography id="seolinks-header" tag="h2" textStyle="title-600" className={styles.header}>
        {section}
      </Typography>
      <Typography textStyle="overline" className={styles.overline}>
        Expert Buying Advice
      </Typography>
      <Typography textStyle="body" className={styles.introText}>
        Know what to look for when you're shopping around
      </Typography>
    </div>
    <nav aria-label="Best buy links">
      <ul className={styles.list}>
        {seoLinkList.map(({ href, text }, ind) => (
          <li className={styles.listItem} key={href}>
            <StandardLink
              href={href}
              data-which-id="Homepage Link"
              data-section={section}
              data-card-name={text}
              data-index={ind + 1}
            >
              {text}
            </StandardLink>
          </li>
        ))}
      </ul>
    </nav>
  </section>
)
