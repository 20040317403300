import type { FunctionComponent } from 'react'
import React from 'react'
import { ArticleCard, Typography } from '@which/seatbelt'

import { newsletterLinkList, section } from '../../constants/newsletterLinks'
import styles from './NewsletterLinksVariant.module.scss'

export const NewsletterLinksVariant: FunctionComponent = () => (
  <section aria-labelledby="newsletterlinks-header">
    <Typography
      className={styles.header}
      id="newsletterlinks-header"
      tag="h2"
      textStyle="title-600"
    >
      {section}
    </Typography>
    <Typography className={styles.introText} textStyle="body">
      Get all kinds of free advice emailed to you regularly
    </Typography>

    <nav aria-label="Newsletter links">
      <ul className={styles.list}>
        {newsletterLinkList.map((newsletterLink, ind) => {
          if (ind < 3) {
            return (
              <li key={`${newsletterLink.href}-variant`}>
                <ArticleCard
                  {...newsletterLink}
                  clampStrapline={false}
                  className={styles.newsletterLink}
                  mainLinkProps={{
                    'data-which-id': 'Homepage Card',
                    'data-section': section,
                    'data-card-name': newsletterLink.title,
                    'data-index': ind + 1,
                  }}
                />
              </li>
            )
          }
          return (
            <li key="all-newsletters-card">
              <ArticleCard
                href="https://www.which.co.uk/about-which/which-newsletters-akBCD7z3vgqS"
                image="https://media.which.gpp.io.io/prod/images/original/c41e19963f73-allnewsletters.png"
                imageAlt=""
                imageSources={[
                  {
                    sizes: '(min-width: 1024px) 260px, (min-width: 768px) 45vw, 90vw',
                    srcset:
                      'https://media.which.gpp.io/prod/images/ar_2to1_320x160/9a1cdc415757-allnewsletters.webp 320w, https://media.which.gpp.io/prod/images/ar_2to1_500x250/9a1cdc415757-allnewsletters.webp 500w, https://media.which.gpp.io/prod/images/ar_2to1_700x350/9a1cdc415757-allnewsletters.webp 700w, https://media.which.gpp.io/prod/images/ar_2to1_900x450/9a1cdc415757-allnewsletters.webp 900w, https://media.which.gpp.io/prod/images/ar_2to1_1200x600/9a1cdc415757-allnewsletters.webp 1200w, https://media.which.gpp.io/prod/images/ar_2to1_1500x750/9a1cdc415757-allnewsletters.webp 1500w, https://media.which.gpp.io/prod/images/ar_2to1_1800x900/9a1cdc415757-allnewsletters.webp 1800w, https://media.which.gpp.io/prod/images/ar_2to1_2100x1050/9a1cdc415757-allnewsletters.webp 2100w, https://media.which.gpp.io/prod/images/ar_2to1_2400x1200/9a1cdc415757-allnewsletters.webp 2400w, https://media.which.gpp.io/prod/images/ar_2to1_2700x1350/9a1cdc415757-allnewsletters.webp 2700w, https://media.which.gpp.io/prod/images/ar_2to1_3000x1500/9a1cdc415757-allnewsletters.webp 3000w, https://media.which.gpp.io/prod/images/ar_2to1_3300x1650/9a1cdc415757-allnewsletters.webp 3300w, https://media.which.gpp.io/prod/images/ar_2to1_3600x1800/9a1cdc415757-allnewsletters.webp 3600w',
                  },
                ]}
                strapline="Expert tips from Which?"
                title="All newsletters"
                clampStrapline={false}
                className={styles.newsletterLink}
                mainLinkProps={{
                  'data-which-id': 'Homepage Card',
                  'data-section': section,
                  'data-card-name': 'All newsletters',
                  'data-index': 4,
                }}
              />
            </li>
          )
        })}
      </ul>
    </nav>
  </section>
)
