import { getImageSources } from '../../../shared/utils'

const sizes =
  '(min-width: 1024px) 175px, (min-width: 934px) 19vw, (min-width: 768px) 26vw, (min-width: 580px) 239px, 40vw'

const ratio = 'ar_1to1'
const renditions = [
  '50x50',
  '100x100',
  '150x150',
  '200x200',
  '300x300',
  '400x400',
  '500x500',
  '600x600',
  '800x800',
  '1000x1000',
  '1200x1200',
  '1500x1500',
  '1800x1800',
]

export const section = 'Product test results'

export const productLinkList = [
  {
    href: '/l/tv-and-home-entertainment/televisions',
    text: 'TVs',
    image: {
      src: 'https://media.product.which.co.uk/prod/images/original/4a722a651c6f-tvsdancing-products.jpg',
      sources: [
        {
          srcset: getImageSources('4a722a651c6f-tvsdancing-products', ratio, renditions),
          sizes,
        },
      ],
    },
  },
  {
    href: '/l/home-appliances/washing-machines',
    text: 'Washing machines',
    image: {
      src: 'https://media.product.which.co.uk/prod/images/original/4903507323c8-washing-machinesdancing-products.jpg',
      sources: [
        {
          srcset: getImageSources(
            '4903507323c8-washing-machinesdancing-products',
            ratio,
            renditions
          ),
          sizes,
        },
      ],
    },
  },
  {
    href: '/l/computing/laptops',
    text: 'Laptops',
    image: {
      src: 'https://media.product.which.co.uk/prod/images/original/52302c026474-laptopsdancing-productsv3.jpg',
      sources: [
        {
          srcset: getImageSources('52302c026474-laptopsdancing-productsv3', ratio, renditions),
          sizes,
        },
      ],
    },
  },
  {
    href: '/l/home-appliances/fridge-freezers',
    text: 'Fridge freezers',
    image: {
      src: 'https://media.which.gpp.io/prod/images/original/3612b65c11ee-fridge-freezers.jpeg',
      sources: [
        {
          srcset: getImageSources('3612b65c11ee-fridge-freezers', ratio, renditions),
          sizes,
        },
      ],
    },
  },
  {
    href: '/reviews/air-fryers',
    text: 'Air fryers',
    image: {
      src: 'https://media.product.which.co.uk/prod/images/original/9083652b1e9a-airfryershomepage-dancing-prodsv2-wchips.jpg',
      sources: [
        {
          srcset: getImageSources(
            '9083652b1e9a-airfryershomepage-dancing-prodsv2-wchips',
            ratio,
            renditions
          ),
          sizes,
        },
      ],
    },
  },
  {
    href: '/l/bedroom/mattresses',
    text: 'Mattresses',
    image: {
      src: 'https://media.product.which.co.uk/prod/images/original/31676c6d0c4d-mattressesdancing-products.jpg',
      sources: [
        {
          srcset: getImageSources('31676c6d0c4d-mattressesdancing-products', ratio, renditions),
          sizes,
        },
      ],
    },
  },
  {
    href: '/l/baby-transport/child-car-seats',
    text: 'Child car seats',
    image: {
      src: 'https://media.product.which.co.uk/prod/images/original/0694f55a8650-child-car-seatshomepage-dancing-prodsv2.jpg',
      sources: [
        {
          srcset: getImageSources(
            '0694f55a8650-child-car-seatshomepage-dancing-prodsv2',
            ratio,
            renditions
          ),
          sizes,
        },
      ],
    },
  },
  {
    href: '/reviews/mobile-phones',
    text: 'Mobile phones',
    image: {
      src: 'https://media.product.which.co.uk/prod/images/original/f5605fa2a15a-homepage-dancing-prodsmobile-phonesv2-wsims.jpg',
      sources: [
        {
          srcset: getImageSources(
            'f5605fa2a15a-homepage-dancing-prodsmobile-phonesv2-wsims',
            ratio,
            renditions
          ),
          sizes,
        },
      ],
    },
  },
]
