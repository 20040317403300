import type { ArticleCardProps } from '@which/seatbelt'

import { getImageSources } from '../../../shared/utils'

export const section = 'Newsletters'

const ratio = 'ar_2to1'
const renditions = [
  '320x160',
  '500x250',
  '700x350',
  '900x450',
  '1200x600',
  '1500x750',
  '1800x900',
  '2100x1050',
  '2400x1200',
  '2700x1350',
  '3000x1500',
  '3300x1650',
  '3600x1800',
]

export const newsletterLinkList: ArticleCardProps[] = [
  {
    href: 'https://signup.which.co.uk/wlp-weeklyscoop-newsletter',
    image: 'https://media.which.gpp.io/prod/images/original/d83ebf4efa71-weeklyscoopnewsletter.jpg',
    imageAlt: '',
    imageSources: [
      {
        srcset: getImageSources('d83ebf4efa71-weeklyscoopnewsletter', ratio, renditions),
        sizes: '(min-width: 1024px) 260px, (min-width: 768px) 45vw, 90vw',
      },
    ],
    strapline: 'Make smarter consumer choices',
    title: 'Weekly Scoop newsletter',
  },
  {
    href: 'https://act.which.co.uk/page/103781/data/1',
    image: 'https://media.product.which.co.uk/prod/images/original/52bdb5f8bd2d-frascams2.jpg',
    imageAlt: '',
    imageSources: [
      {
        srcset: getImageSources('52bdb5f8bd2d-frascams2', ratio, renditions),
        sizes: '(min-width: 1024px) 260px, (min-width: 768px) 45vw, 90vw',
      },
    ],
    strapline: 'Outsmart the scammers',
    title: 'Scam Alerts newsletter',
  },
  {
    href: 'https://signup.which.co.uk/wlp-money-newsletter',
    image: 'https://media.product.which.co.uk/prod/images/original/cb419a137171-framoney2.jpg',
    imageAlt: '',
    imageSources: [
      {
        srcset: getImageSources('cb419a137171-framoney2', ratio, renditions),
        sizes: '(min-width: 1024px) 260px, (min-width: 768px) 45vw, 90vw',
      },
    ],
    strapline: 'Get a firmer grip on your finances',
    title: 'Money newsletter',
  },
  {
    href: 'https://signup.which.co.uk/wlp-familynewsletter',
    image: 'https://media.product.which.co.uk/prod/images/original/d4f34bab3475-frafam21.jpg',
    imageAlt: '',
    imageSources: [
      {
        srcset: getImageSources('d4f34bab3475-frafam21', ratio, renditions),
        sizes: '(min-width: 1024px) 260px, (min-width: 768px) 45vw, 90vw',
      },
    ],
    strapline: 'Keep costs down and kids happy',
    title: 'Family newsletter',
  },
]
