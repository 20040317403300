import type { FunctionComponent } from 'react'
import React from 'react'
import { ArticleCard, Typography } from '@which/seatbelt'

import { magazineLinkList, section } from '../../constants/magazineLinks'
import styles from './MagazineLinks.module.scss'

export const MagazineLinks: FunctionComponent = () => (
  <section aria-labelledby="magazinelinks-header">
    <Typography className={styles.header} id="magazinelinks-header" tag="h2" textStyle="title-600">
      {section}
    </Typography>
    <Typography className={styles.introText} textStyle="body">
      Get answers from our experts
    </Typography>

    <nav aria-label="Magazines links and services">
      <ul className={styles.list}>
        {magazineLinkList.map((magazineLink, ind) => (
          <li key={magazineLink.href}>
            <ArticleCard
              {...magazineLink}
              clampStrapline={false}
              className={styles.magazineLink}
              mainLinkProps={{
                'data-which-id': 'Homepage Card',
                'data-section': section,
                'data-card-name': magazineLink.title,
                'data-index': ind + 1,
              }}
            />
          </li>
        ))}
      </ul>
    </nav>
  </section>
)
