import type { FunctionComponent } from 'react'
import React from 'react'
import { AnimationWrapper, BaseLink, Picture, Typography } from '@which/seatbelt'

import { productLinkList, section } from '../../constants/productLinks'
import styles from './ProductLinks.module.scss'

export const ProductLinks: FunctionComponent = () => (
  <section aria-labelledby="productlinks-header">
    <div className={styles.headerWrapper}>
      <Typography id="productlinks-header" tag="h2" textStyle="title-600" className={styles.header}>
        {section}
      </Typography>
      <Typography textStyle="overline" className={styles.overline}>
        Digital & full access members
      </Typography>
      <Typography textStyle="body" className={styles.introText}>
        Find our Best Buys so you don't waste your money
      </Typography>
    </div>
    <nav aria-labelledby="productlinks-header">
      <ul className={styles.list}>
        {productLinkList.map(({ href, image: { src, sources }, text }, ind) => (
          <li className={styles.listItem} key={href}>
            <BaseLink
              href={href}
              className={styles.link}
              data-which-id="Homepage Link"
              data-section={section}
              data-card-name={text}
              data-index={ind + 1}
              appearance="primary"
            >
              <Picture
                aspectRatioMain="one-to-one"
                alt=""
                src={src}
                sources={sources}
                className={styles.imageContainer}
                imageClassName={styles.image}
              />
              <Typography textStyle="body-intro">
                <AnimationWrapper>{text}</AnimationWrapper>
              </Typography>
            </BaseLink>
          </li>
        ))}
      </ul>
    </nav>
  </section>
)
