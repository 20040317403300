import type { FunctionComponent } from 'react'
import React from 'react'
import { ArticleCard, NavigationLink, Typography } from '@which/seatbelt'

import { newsletterLinkList, section } from '../../constants/newsletterLinks'
import styles from './NewsletterLinks.module.scss'

export const NewsletterLinks: FunctionComponent = () => (
  <section aria-labelledby="newsletterlinks-header">
    <Typography
      className={styles.header}
      id="newsletterlinks-header"
      tag="h2"
      textStyle="title-600"
    >
      {section}
    </Typography>
    <Typography className={styles.introText} textStyle="body">
      Get all kinds of free advice emailed to you regularly
    </Typography>

    <nav aria-label="Newsletter links">
      <ul className={styles.list}>
        {newsletterLinkList.map((newsletterLink, ind) => (
          <li key={newsletterLink.href}>
            <ArticleCard
              {...newsletterLink}
              clampStrapline={false}
              className={styles.newsletterLink}
              mainLinkProps={{
                'data-which-id': 'Homepage Card',
                'data-section': section,
                'data-card-name': newsletterLink.title,
                'data-index': ind + 1,
              }}
            />
          </li>
        ))}
      </ul>
      <NavigationLink
        href="https://www.which.co.uk/about-which/which-newsletters-akBCD7z3vgqS"
        data-which-id="Homepage Link"
        data-section={section}
        data-card-name="All newsletters"
        data-index={newsletterLinkList.length + 1}
      >
        All newsletters
      </NavigationLink>
    </nav>
  </section>
)
