// @TODO PNJ-138: Cleanup unneeded variant code after test completion
import all from './icons/all.svg'
import babyChild from './icons/baby_child.svg'
import broadbandPackages from './icons/broadband_packages.svg'
import cars from './icons/cars.svg'
import homeGarden from './icons/home_garden.svg'
import legal from './icons/legal-sm.svg'
import money from './icons/money.svg'
import powerOfAttorney from './icons/power_of_attorney.svg'
import technology from './icons/technology.svg'
import travel from './icons/travel.svg'
import trustedTrader from './icons/trusted_trader.svg'
import writeAWill from './icons/write_a_will.svg'

export const section = 'Popular links'

export const popularLinksData = [
  {
    href: 'https://www.which.co.uk/home-and-garden',
    text: 'Home & garden',
    icon: homeGarden,
  },
  {
    href: 'https://www.which.co.uk/tech-and-appliances',
    text: 'Tech & appliances',
    icon: technology,
  },
  {
    href: 'https://www.which.co.uk/money',
    text: 'Money',
    icon: money,
  },
  {
    href: 'https://www.which.co.uk/l/cars',
    text: 'Cars',
    icon: cars,
  },
  {
    href: 'https://www.which.co.uk/baby-and-child',
    text: 'Baby & child',
    icon: babyChild,
  },
  {
    href: 'https://www.which.co.uk/l/travel',
    text: 'Travel',
    icon: travel,
  },
]

export const servicesData = [
  {
    label: 'Comparison services',
    links: [
      {
        href: 'https://energy.which.co.uk/?utm_source=Which&utm_medium=Referral&utm_campaign=New_Homepage',
        text: 'Compare energy deals',
      },
      {
        href: 'https://broadband.which.co.uk/#/?productType=broadband,phone?utm_source=Which&utm_medium=Referral&utm_campaign=New_Homepage',
        text: 'Compare broadband deals',
      },
      {
        href: 'https://mobilephones.which.co.uk/?utm_source=Which&utm_medium=Referral&utm_campaign=New_Homepage',
        text: 'Compare mobile deals',
      },
    ],
  },
  {
    label: 'Estate planning',
    links: [
      {
        href: 'https://whichwills.which.co.uk/wills/?utm_source=Which&utm_medium=Referral&utm_campaign=New_Homepage',
        text: 'Write a will',
      },
      {
        href: 'https://whichwills.which.co.uk/power-of-attorney/?utm_source=Which&utm_medium=Referral&utm_campaign=New_Homepage',
        text: 'Set up a power of attorney',
      },
    ],
  },
  {
    label: 'Financial and legal services',
    links: [
      {
        href: 'https://legalservice.which.co.uk/?utm_source=Which&utm_medium=Referral&utm_campaign=New_Homepage',
        text: 'Get legal advice',
      },
      { href: 'https://signup.which.co.uk/wlp-money', text: 'Get 1-to-1 money guidance' },
    ],
  },
  {
    label: 'Trade services',
    links: [
      {
        href: 'https://trustedtraders.which.co.uk/?utm_source=Which&utm_medium=Referral&utm_campaign=New_Homepage',
        text: 'Find traders you can trust',
      },
    ],
  },
]

export const lifeAdminLinksData = [
  {
    href: 'https://whichwills.which.co.uk/wills/',
    text: 'Write a will',
    icon: writeAWill,
  },
  {
    href: 'https://trustedtraders.which.co.uk/?',
    text: 'Home improvements',
    icon: trustedTrader,
  },
  {
    href: 'https://legalservice.which.co.uk/',
    text: 'Legal advice',
    icon: legal,
  },
  {
    href: 'https://broadband.which.co.uk/',
    text: 'Compare broadband',
    icon: broadbandPackages,
  },
  {
    href: 'https://whichwills.which.co.uk/power-of-attorney/',
    text: 'Power of attorney',
    icon: powerOfAttorney,
  },
  {
    href: 'https://which.co.uk/compare-providers',
    text: 'More services',
    icon: all,
  },
]
