import type { FunctionComponent } from 'react'
import React from 'react'
import { ContentCardV2, NavigationLink, Typography } from '@which/seatbelt'

import { campaignLinkList, section } from '../../constants/campaignLinks'
import styles from './CampaignLinks.module.scss'

export const CampaignLinks: FunctionComponent = () => (
  <section aria-labelledby="campaignlinks-header">
    <Typography id="campaignlinks-header" tag="h2" textStyle="title-600" className={styles.header}>
      {section}
    </Typography>
    <Typography textStyle="body" className={styles.introText}>
      Let's work together to change things
    </Typography>
    <nav aria-labelledby="campaignlinks-header">
      <ul className={styles.list}>
        {campaignLinkList.map((campaignLink, index) => (
          <li className={styles.listItem} key={campaignLink.primaryLink}>
            <ContentCardV2
              {...campaignLink}
              arrangement="horizontal"
              trackingData={{
                'data-which-id': 'Homepage Card',
                'data-section': section,
                'data-card-name': campaignLink.title,
                'data-index': `${index + 1}`,
              }}
            />
          </li>
        ))}
      </ul>
      <NavigationLink
        href="https://campaigns.which.co.uk/"
        icon="chevronRight"
        data-which-id="Homepage Link"
        data-section={section}
        data-card-name="All campaigns"
        data-index={campaignLinkList.length + 1}
      >
        All campaigns
      </NavigationLink>
    </nav>
  </section>
)
