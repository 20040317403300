import type { FunctionComponent } from 'react'
import React from 'react'
import { Grid, GridItem } from '@which/seatbelt'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import classnames from 'classnames'

import type {
  FeaturedLinks as FeaturedLinksType,
  MemberBenefits,
  MostReadLinks as MostReadLinksType,
  ToolSignpost,
} from '../../../generated/frontend'
import { CampaignLinks } from './CampaignLinks'
import { FeaturedLinks } from './FeaturedLinks'
import { FeaturedLinksWithMemberBenefits } from './FeaturedLinksWithMemberBenefits'
import styles from './Homepage.module.scss'
import { HomepageCTA } from './HomepageCTA'
import { MagazineLinks } from './MagazineLinks'
import { defaultMemberBenefitsDetails } from './MemberBenefits/constants/memberBenefits'
import { MostReadLinks } from './MostReadLinks'
import { NewsletterLinks } from './NewsletterLinks'
import { NewsletterLinksVariant } from './NewsletterLinksVariant'
import { PopularOnWhich } from './PopularOnWhich'
import { ProductLinks } from './ProductLinks'
import { SeoLinks } from './SeoLinks'

export const Homepage: FunctionComponent<HomepageProps> = ({
  toolCTAs,
  featuredLinks,
  mostReadLinks,
  memberBenefits,
}) => {
  const toolCTA1 = mapData('toolCTA1', toolCTAs) as ToolSignpost
  const featuredLinks1 = mapData('FeaturedLinks1', featuredLinks) as FeaturedLinksType
  const featuredLinks2 = mapData('FeaturedLinks2', featuredLinks) as FeaturedLinksType
  const featuredLinks3 = mapData('FeaturedLinks3', featuredLinks) as FeaturedLinksType
  const featuredLinks4 = mapData('FeaturedLinks4', featuredLinks) as FeaturedLinksType
  const featuredLinks5 = mapData('FeaturedLinks5', featuredLinks) as FeaturedLinksType

  const newsletterVariant = useFeatureIsOn('homepage-newsletter-variant')
  const newsletterLocation = useFeatureIsOn('homepage-newsletter-location')

  const newNewsletter = () => (
    <div className={classnames(styles.sectionSpacing)}>
      <Grid className="w-page-wrapper">
        <GridItem
          columnStart={{ large: 2 }}
          span={{ large: 10 }}
          className={styles.mobileMaxWidthWrapper}
        >
          <NewsletterLinksVariant />
        </GridItem>
      </Grid>
    </div>
  )
  const oldNewsletter = () => (
    <div className={classnames(styles.sectionSpacing, styles.sectionGrey)}>
      <Grid className="w-page-wrapper">
        <GridItem
          columnStart={{ large: 2 }}
          span={{ large: 10 }}
          className={styles.mobileMaxWidthWrapper}
        >
          <NewsletterLinks />
        </GridItem>
      </Grid>
    </div>
  )

  const renderNewsLetterVariant = () => {
    if (newsletterVariant) {
      return newNewsletter()
    }

    return oldNewsletter()
  }

  return (
    <>
      {featuredLinks1 && (
        <FeaturedLinksWithMemberBenefits
          featuredLinks={{
            ...featuredLinks1,
            identifier: '1',
            lazyLoad: false,
          }}
          memberBenefits={{ ...memberBenefits, details: defaultMemberBenefitsDetails }}
        />
      )}
      {mostReadLinks && (
        <Grid className={classnames('w-page-wrapper', styles.sectionSpacing)}>
          <GridItem
            columnStart={{ large: 2 }}
            span={{ large: 10 }}
            className={styles.mobileMaxWidthWrapper}
          >
            <MostReadLinks {...mostReadLinks} />
          </GridItem>
        </Grid>
      )}
      <div className={classnames(styles.sectionSpacing, styles.sectionGrey)}>
        <Grid className="w-page-wrapper">
          <GridItem
            columnStart={{ medium: 2, large: 2 }}
            span={{ medium: 10, large: 10 }}
            className={styles.mobileMaxWidthWrapper}
          >
            <PopularOnWhich />
          </GridItem>
        </Grid>
      </div>
      {/*
        Experiment CDS-006
          If newsletterLocation flag is on, can be either:
            Old version (Control) OR New version
      */}
      {newsletterLocation && renderNewsLetterVariant()}
      <div className={newsletterLocation && newsletterVariant ? styles.sectionGrey : ''}>
        {featuredLinks2 && <FeaturedLinks {...featuredLinks2} identifier="2" />}
      </div>
      <div
        className={classnames(styles.sectionSpacing, {
          [styles.bottomOnly]: !newsletterLocation,
        })}
      >
        <Grid className="w-page-wrapper">
          <GridItem
            columnStart={{ large: 2, medium: 3 }}
            span={{ large: 10, medium: 8 }}
            className={styles.mobileMaxWidthWrapper}
          >
            {toolCTA1 && <HomepageCTA {...toolCTA1} />}
          </GridItem>
        </Grid>
      </div>
      {featuredLinks3 && <FeaturedLinks {...featuredLinks3} identifier="3" />}
      {featuredLinks4 && <FeaturedLinks {...featuredLinks4} identifier="4" />}
      {featuredLinks5 && <FeaturedLinks {...featuredLinks5} identifier="5" />}
      <div className={styles.sectionSpacing}>
        <Grid className="w-page-wrapper">
          <GridItem
            columnStart={{ large: 2 }}
            span={{ large: 10 }}
            className={styles.mobileMaxWidthWrapper}
          >
            <ProductLinks />
          </GridItem>
        </Grid>
      </div>
      {/*
        Experiment CDS-006
          If newsletterLocation && newsletterVariant flags is off, can only be:
            OLD version (control)
      */}
      {!newsletterLocation && !newsletterVariant && oldNewsletter()}
      <div className={styles.sectionSpacing}>
        <Grid className="w-page-wrapper">
          <GridItem
            columnStart={{ large: 2 }}
            span={{ large: 10 }}
            className={styles.mobileMaxWidthWrapper}
          >
            <CampaignLinks />
          </GridItem>
        </Grid>
      </div>
      <div className={classnames(styles.sectionSpacing, styles.sectionGrey)}>
        <Grid className="w-page-wrapper">
          <GridItem
            columnStart={{ large: 2 }}
            span={{ large: 10 }}
            className={styles.mobileMaxWidthWrapper}
          >
            <MagazineLinks />
          </GridItem>
        </Grid>
      </div>
      <div className={styles.sectionSpacing}>
        <Grid className="w-page-wrapper">
          <GridItem
            columnStart={{ large: 2 }}
            span={{ large: 10 }}
            className={styles.mobileMaxWidthWrapper}
          >
            <SeoLinks />
          </GridItem>
        </Grid>
      </div>
    </>
  )
}

///////// IMPLEMENTATION /////////

type HomepageProps = {
  toolCTAs: ToolSignpost[]
  featuredLinks: FeaturedLinksType[]
  mostReadLinks: MostReadLinksType
  memberBenefits: MemberBenefits
}

const mapData = (identifier: string, data: Array<ToolSignpost | FeaturedLinksType>) =>
  data.find((widget) => widget?.identifier === identifier)
