import React from 'react'
import { BaseLink, Picture, Typography } from '@which/seatbelt'

import { lifeAdminLinksData } from '../../../constants/popularOnWhich'
import styles from '../PopularOnWhich.module.scss'

interface Props {
  id: string
  title: string
  description: string
}

export const LifeAdmin: React.FC<Props> = ({ id, title, description }) => (
  <div className={styles.linkGridWrapper}>
    <Typography id={id} tag="h2" textStyle="title-600" className={styles.header}>
      {title}
    </Typography>
    <Typography textStyle="body" className={styles.subHeader}>
      {description}
    </Typography>
    <nav aria-labelledby={id} className={styles.navSection}>
      <ul className={styles.list}>
        {lifeAdminLinksData.map(({ href, text, icon }, ind) => (
          <li key={`${href}-life-admin`}>
            <BaseLink
              className={styles.link}
              href={href}
              data-which-id="Homepage Link"
              data-section={title}
              data-card-name={text}
              data-index={ind + 1}
              appearance="primary"
            >
              <Picture className={styles.picture} src={icon} width="50" height="50" aria-hidden />
              <Typography tag="h3" textStyle="title-400">
                {text}
              </Typography>
            </BaseLink>
          </li>
        ))}
      </ul>
    </nav>
  </div>
)
