import type { FunctionComponent } from 'react'
import React from 'react'
import { BaseLink, Picture, Typography } from '@which/seatbelt'

import classnames from 'classnames'

import { popularLinksData, section } from '../../constants/popularOnWhich'
import { LifeAdmin } from './LifeAdmin/LifeAdmin'
import styles from './PopularOnWhich.module.scss'

export const PopularOnWhich: FunctionComponent = () => (
  <section className={styles.section} aria-labelledby="popular-on-which-header">
    <Typography
      id="popular-on-which-header"
      tag="h2"
      textStyle="title-600"
      className={styles.header}
    >
      {section}
    </Typography>
    <Typography textStyle="body" className={styles.subHeader}>
      Smart advice on products and brands
    </Typography>
    <nav aria-labelledby="popular-on-which-header" className={styles.navSection}>
      <ul className={styles.list}>
        {popularLinksData.map(({ href, text, icon }, ind) => (
          <li className={styles.listItem} key={href}>
            <BaseLink
              className={styles.link}
              href={href}
              data-which-id="Homepage Link"
              data-section={section}
              data-card-name={text}
              data-index={ind + 1}
              appearance="primary"
            >
              <Picture
                className={classnames(styles.picture, styles.imageOffset)}
                src={icon}
                width="50"
                height="50"
                aria-hidden
              />
              <Typography tag="h3" textStyle="title-400">
                {text}
              </Typography>
            </BaseLink>
          </li>
        ))}
      </ul>
    </nav>
    <LifeAdmin
      id="life-admin-links"
      title="Get your life admin sorted"
      description="Use our services and comparison tools to get a head start"
    />
  </section>
)
