import type { ArticleCardProps } from '@which/seatbelt'

import { getImageSources } from '../../../shared/utils'

const sizes = '(min-width: 1024px) 260px, (min-width: 768px) 45vw, 90vw'

const ratio = 'ar_2to1'
const renditions = [
  '320x160',
  '500x250',
  '700x350',
  '900x450',
  '1200x600',
  '1500x750',
  '1800x900',
  '2100x1050',
  '2400x1200',
  '2700x1350',
  '3000x1500',
  '3300x1650',
  '3600x1800',
]

export const section = 'Memberships'

export const magazineLinkList: ArticleCardProps[] = [
  {
    href: 'https://signup.which.co.uk/wlp-gardening',
    image:
      'https://media.product.which.co.uk/prod/images/original/b91511c6ec02-upsellbannersgardening.jpg',
    imageAlt: '',
    imageSources: [
      {
        srcset: getImageSources('b91511c6ec02-upsellbannersgardening', ratio, renditions),
        sizes,
      },
    ],
    strapline: 'Get the garden you want all year round',
    title: 'Which? Gardening',
  },
  {
    href: 'https://signup.which.co.uk/wlp-travel',
    image:
      'https://media.product.which.co.uk/prod/images/original/10ab426d4062-upsellbannerstravel.jpg',
    imageAlt: '',
    imageSources: [
      {
        srcset: getImageSources('10ab426d4062-upsellbannerstravel', ratio, renditions),
        sizes,
      },
    ],
    strapline: 'Plan all your holidays with confidence',
    title: 'Which? Travel',
  },
  {
    href: 'https://signup.which.co.uk/wlp-money',
    image:
      'https://media.product.which.co.uk/prod/images/original/b10478655e20-upsellbannersmoney.jpg',
    imageAlt: '',
    imageSources: [
      {
        srcset: getImageSources('b10478655e20-upsellbannersmoney', ratio, renditions),
        sizes,
      },
    ],
    strapline: 'Make your money go further',
    title: 'Which? Money',
  },
  {
    href: 'https://signup.which.co.uk/wlp-tech-support',
    image:
      'https://media.product.which.co.uk/prod/images/original/2b97d941a08b-upsellbannerstech.jpg',
    imageAlt: '',
    imageSources: [
      {
        srcset: getImageSources('2b97d941a08b-upsellbannerstech', ratio, renditions),
        sizes,
      },
    ],
    strapline: 'Keep on top of your tech',
    title: 'Which? Tech Support',
  },
]
