import { getImageSources } from '../../../shared/utils'

const sizes = '(min-width: 1440px) 500px, (min-width: 768px) 50vw, 90vw'

const ratio = 'ar_2to1'
const renditions = [
  '320x160',
  '500x250',
  '700x350',
  '900x450',
  '1200x600',
  '1500x750',
  '1800x900',
  '2100x1050',
  '2400x1200',
  '2700x1350',
  '3000x1500',
  '3300x1650',
  '3600x1800',
]

export const section = 'Campaigns'

export const campaignLinkList: ContentCardProps = [
  {
    primaryLink: 'https://www.which.co.uk/campaigns/the-right-to-connect',
    title: 'The Right to Connect',
    description:
      "Mobile and broadband providers are duping consumers with their sneaky mid-contract price hikes. We're demanding big broadband and mobile phone providers drop these shady practices. Agree? Sign the petition.",
    imgObj: {
      aspectRatioMain: 'two-to-one',
      imageAlt: 'The right to connect, Which? Petition',
      str: 'https://media.which.gpp.io/prod/images/gm_list_thumbnail/af1b565f5acb-trtc-campaign-thumbnails.jpg',
      imageSources: [
        {
          srcset: getImageSources('af1b565f5acb-trtc-campaign-thumbnails', ratio, renditions),
          sizes,
        },
      ],
    },
    buttonLinkText: 'Sign the petition',
  },
  {
    primaryLink: 'https://www.which.co.uk/campaigns/affordable-food',
    title: 'Affordable food for all',
    description:
      "Millions of families are skipping meals to survive the cost of living crisis. We're calling on supermarkets to commit to clear pricing and better access to budget ranges. Sign our petition for affordable food for all!",
    imgObj: {
      aspectRatioMain: 'two-to-one',
      imageAlt: 'Affordable food for all, Which? Petition',
      str: 'https://media.product.which.co.uk/prod/images/original/d16b57bac83f-affa-campaign-thumbnails.jpg',
      imageSources: [
        {
          srcset: getImageSources('d16b57bac83f-affa-campaign-thumbnails', ratio, renditions),
          sizes,
        },
      ],
    },
    buttonLinkText: 'Sign the petition',
  },
  {
    primaryLink: 'https://www.which.co.uk/campaigns/tech-giants-responsibility',
    title: 'Make tech giants take responsibility',
    description:
      "Online platforms are getting away with allowing scams, fake reviews, and dangerous products to harm users on their websites. We're demanding robust new laws to ensure tech giants do better. Sign our petition for a safer online future.",
    imgObj: {
      aspectRatioMain: 'two-to-one',
      imageAlt: 'Make tech giants take responsibility, Which? Petition',
      str: 'https://media.product.which.co.uk/prod/images/original/f435111d7439-tech-giants-campaign-thumbnails.jpg',
      imageSources: [
        {
          srcset: getImageSources(
            'f435111d7439-tech-giants-campaign-thumbnails',
            ratio,
            renditions
          ),
          sizes,
        },
      ],
    },
    buttonLinkText: 'Sign the petition',
  },
]

type ContentCardProps = {
  primaryLink: string
  title: string
  description: string
  imgObj: {
    aspectRatioMain:
      | 'one-to-one'
      | 'two-to-one'
      | 'three-to-one'
      | 'three-to-five'
      | 'four-to-three'
      | undefined
    imageAlt: string
    str: string
    imageSources: {
      srcset: string
      sizes: string
    }[]
  }
  buttonLinkText: string
}[]
